.rating{
    display:flex;
    align-items: center;
}

.rating > * {
    margin-right: 4px;
}

.rating p{
    font-size: 0.85rem;
}