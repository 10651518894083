:root{
     --element-spacing: 1rem;
}

.body{

    background:linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../assets/people_image.jpg');
    background-color: black;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;

}

.block{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}

.parent-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 10% 0;
}

.select-button{
    margin: 5% 0;
    margin-right: var(--element-spacing);
    height: 100px;
    width: 100px;
}



.logo{
    width: 150px;
    margin: 2% 0;
    margin-left: 2%;
}

.paragraph{
    font-size: 25px;
    font-family: 'Avenir';
    color: white;
    
}