.search-summary{
    width: 100%;
    
    padding: 1.5rem 0;
    display: flex;
    justify-content: space-between;
}

.search-summary p{
    font-size: 0.9rem;
}

.search-summary h1:global(.subtitle) {
    margin-bottom: 0;
}

.container{
    display:flex;
    background: rgb(245,245,245);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(230, 230, 230);
}

.container > *{
    max-width: 1100px;

}

.filters{
    display: flex;
    width: 100%;
}

.filters > *{
    margin-right: 0.7rem;
}