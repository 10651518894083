.input-control{
    min-width: 320px;
}

:global(.button).search-button{
    background: #212121;
    border:none;
}

:global(.icon).search-icon{
    color:white;
}